import styled from "styled-components";
import { red } from "../colors";

const H2 = styled.h2`
  color: ${red};
  font-size: 28rem;
  font-weight: 700;
  margin: 0 0 14px 0;
  line-height: 1.2;
`;

export default H2;
